let theme = {
    // main: "#163d2c",
    main: "#0A192F",
    light: "#112240",
    dark: "#020C1B",
    secondary: "#ad1d44",
    breakpoints: {
        lg: 1280,
        md: 960,
        sm: 600,
    }
}

export { 
    theme,
};
